import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { HashList } from 'hashlist-typescript';
import { showToast } from "../utils/toast.utils";
import { getS3SignedUrl, persistDirections } from "./upload.api"
import { ImagePreview } from "./classes/ImagePreview"
import { setfileDetails, useGlobalState } from "../state";
import ReactGA from 'react-ga';

import "./upload.css";
import "../css/skeleton/normalize.css";
import "../css/skeleton/skeleton.css";
import MultipleImageUploadComponent from "./components/MultiUpload";

  const makeid = (length: number) => {
    let result           = '';
    let characters       = 'ABCDEFGHIJKLMNPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const filePreviewList = new HashList<ImagePreview>();

function UploadDirections() {
  const [fileList, setFileList] = useState(new HashList<ImagePreview>());
  const UNIQUE_ID = makeid(7);

  setTimeout(() => ReactGA.pageview("/upload"), 1000);

  let history = useHistory();

  return (
    <div>
      <div id="outer">
      <div id="col1"></div>
      <div id="col2"></div>
      <div id="col3"></div>
      <div id="col4"></div>
      <div id="col5"></div>
      <div id="col6"></div>
      </div>
      <div id="clearer"></div>
      <div className="container">
        <div className="row">
          <div className="one-third column filler">&nbsp;</div>
          <div className="one-third column">
          <div className="heading-center">Deliverease.</div>
      <div className="">
        <p>We make it easy to provide directions to your front door in three simple steps:</p>
        <ol>
          <li>Upload photos of landmarks around your residence (e.g. a front gate or the walkway up to your building)</li>
          <li>Add text to each photo, guiding the courier to your door</li>
          <li>Share your unique link in the "instructions" section of your favorite delivery apps</li>
        </ol>
      </div>
            </div>
            <div className="one-third column filler">&nbsp;</div>
        </div>
      </div>

      <MultipleImageUploadComponent
        id={UNIQUE_ID}
      />
      <div className="upload-footer"> 
        <a href="https://docs.google.com/forms/d/e/1FAIpQLSfwUdQWqjg3cuml00kYi-8CoJdC7484F2ljY8e1TWv49a2MPg/viewform?usp=sf_link"
        target="_blank">
          Contact us</a> with any questions or inquires. 
      </div>
      </div>
  );
}

export default UploadDirections;
