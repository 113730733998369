import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
// import HomePage from "./homepage/homepage";
import AppRouter from "./AppRouter";
import ReactGA from 'react-ga';

import * as serviceWorker from "./serviceWorker";

ReactGA.initialize('UA-175203297-1');

ReactDOM.render(<AppRouter />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
