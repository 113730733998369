class ImagePreview {
    private _url: string;
    private _description: string;
    private _name: string;
    private _file: File;

    constructor(url: string, description: string, name: string, file: File) {
      this._description = description;
      this._url = url;
      this._name = name;
      this._file = file;
    }    
    get url(): string { return this._url }

    get description(): string { return this._description }

    set description(description: string) {
        this._description = description;
    }

    get name(): string { return this._name }

    set name(name: string) {
        this._name = name;
    }

    get file(): File { return this._file }

    set file(file: File) {
        this._file = file;
    }

    public toString = () : string => {
        return `ImagePreview (url: ${this.url}, description: ${this.description}, name: ${this.name})`;
    }
}

export { ImagePreview }
