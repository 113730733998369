import React from "react";
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import HomePage from "./homepage/homepage";
import { GlobalStateProvider } from "./state";
import UploadDirections from "./upload/upload";
import CreateSteps from "./steps/steps";
import DisplayDirections from "./directions/directions";
import ShowConfirmation from "./confirmation/confirmation";

require("./css/microdirections.css");

function AppRouter() {
  return (
    <Router>
      <GlobalStateProvider>
        <ToastContainer />
        <Switch>
          <Route exact path="/" component={UploadDirections} />
          <Route exact path="/app/" component={UploadDirections} />
          <Route exact path="/upload/" component={UploadDirections} />
          <Route exact path="/steps/" component={CreateSteps} />
          <Route exact path="/confirmation/" component={ShowConfirmation} />
          <Route exact path="/directions/:directionsId" component={DisplayDirections} />
          <Route path="*">
              <h1>404 Page Not Found.</h1>
          </Route>
        </Switch>
      </GlobalStateProvider>
    </Router>
  );
}

export default AppRouter;
