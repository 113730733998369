import React, { useState, useEffect } from "react";
import { HashList } from 'hashlist-typescript';
import { showToast } from "../utils/toast.utils";
import { getS3SignedUrl, persistDirections, getDirections } from "./steps.api"
import { setfileDetails, useGlobalState } from "../state";
import { useHistory } from "react-router-dom";

import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'
 
import "./steps.css";
import "../css/skeleton/normalize.css";
import "../css/skeleton/skeleton.css";

  const stepDescriptionMap: any = {};

  // specify upload params and url for your files
  const getUploadParams = async (id: string, { meta: { name } }: {meta: any}) => {
    const s3SigningMethod = "post";
    const { fields, url } = await getS3SignedUrl(id + "/" + name, s3SigningMethod)
    return { fields, meta: {}, url: url }
  }

  const renderFilePreview = (filePreviewList: any) => {
    const handleTextAreaChange = (ev: any, url: string): void => {
      stepDescriptionMap[url] = ev.target.value;
    }
    return [...filePreviewList].map((imagePreview, index) => {
      const { url, description } = imagePreview //destructuring
      return (
         <div key={url} className="step">
            <img src={url} />
            <p> {description} </p>
            <textarea onChange={event => { handleTextAreaChange(event, url) }} />
         </div>
      )
   })
  }

function CreateSteps() {
  const [{ id, filePreviewList, files }] = useGlobalState("fileDetails");
  let history = useHistory();

  const handleSubmit = async () => {
    // files.map(async f => await f.restart())
    
    const directionsList: any[] = [];

    [...filePreviewList].map((imagePreview, index) => {
      const { url, description, name } = imagePreview //destructuring
      directionsList.push({
        "orderKey": index + 1,
        "imageKey": name,
        "instructions": stepDescriptionMap[url],
      });
   })
    await persistDirections(id, directionsList).then((data) => {
      history.push("/confirmation")
      console.log(id);
    },(err) => {
      console.error(err);
    })
  }

  return (
    <div className="step-container">
      {renderFilePreview(filePreviewList)}
      <button onClick={handleSubmit}> submit </button>
    </div>
  );
}

export default CreateSteps;
