import { get, post } from "../ApiProvider";

const preSignedUrlUri = "/s3/signing";
const directionsUri = "/directions";

const getS3SignedUrl = async (fileName, s3SigningMethod) => {
  const params = {
    "params": {
      "fileName": fileName,
      "method": s3SigningMethod,
    },
  };
  return await get(preSignedUrlUri, params);
};

const persistDirections = async (id, directionsList) => {
  //console.log(files)
  const payload = {
    "id": id,
    "directions": directionsList
  }
  console.log(payload);
  return await post(directionsUri, payload);
}

const getDirections = async (id) => {
  const params = {
    "params": {
      "uniqueId": id,
    },
  };
  return await get(directionsUri, params);
}

export { getS3SignedUrl, persistDirections, getDirections };
