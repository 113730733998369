import { createGlobalState } from "react-hooks-global-state";
import { ImagePreview } from "./upload/classes/ImagePreview"
import { HashList } from 'hashlist-typescript';
import { string } from "prop-types";

const {
  GlobalStateProvider,
  setGlobalState,
  useGlobalState,
} = createGlobalState({
  fileDetails: {
    id: "",
    filePreviewList: new HashList(),
    files: new Array(),
  },
});

export const setfileDetails = (targetInput, targetValue) => {
  setGlobalState("fileDetails", (v) => ({
    ...v,
    [targetInput]: targetValue,
  }));
};

export { GlobalStateProvider, useGlobalState };
