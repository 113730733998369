import React, { useState } from 'react';
import { HashList } from 'hashlist-typescript';
import { useHistory } from "react-router-dom";
import { ImagePreview } from '../classes/ImagePreview';
import { getS3SignedUrl, uploadFileToS3, persistDirections } from '../upload.api';
import { setfileDetails, useGlobalState } from "../../state";
import ReactGA from 'react-ga';
import imageCompression from 'browser-image-compression';

const filePreviewMap = new Map<String, String>();
const filePreviewList = new HashList<ImagePreview>();
const compressionOptions = {
    maxSizeMB: 0.5,
    maxWidthOrHeight: 1080,
    useWebWorker: true
  }

interface IMultiUploadProps {
    id: string,
}

function MultipleImageUploadComponent(props: IMultiUploadProps) {
    let history = useHistory();
    
    const UNIQUE_ID = props.id;
    const [filePreviews, setFilePreviews] = useState<ImagePreview[]>([]);
    const [showLoadingSpinner, setShowLoadingSpinner] = useState(false);

    const addImage = (previewUrl: string, image: File) => {
      const imageName = image["name"];
      const imagePreview = new ImagePreview(previewUrl, '', imageName, image);
      const imagePreviewHash = filePreviewList.append(imagePreview);
      filePreviewMap.set(previewUrl, imagePreviewHash);

      setFilePreviews(filePreviewList.toArray());
    }

    const removeImage = (previewUrl: string) => {
      if(filePreviewList.length == 1) {
          filePreviewList.removeHead();
          filePreviewList.removeTail();
      }

      if(filePreviewList.head && previewUrl == filePreviewList.head.url) {
        filePreviewList.removeHead();
      } else if(filePreviewList.tail && previewUrl == filePreviewList.tail.url) {
          filePreviewList.removeTail();
      }

      const imagePreviewHash = filePreviewMap.get(previewUrl);
      if (imagePreviewHash != undefined) {
        filePreviewList.remove(imagePreviewHash.toString());
        filePreviewMap.delete(previewUrl);

        setFilePreviews(filePreviewList.toArray());
      }

      ReactGA.event({
        category: 'User',
        action: 'Removed an image'
      });
    }

    const renderFilePreview = () => {
        return [...filePreviews].map((imagePreview) => {
          const { url, description } = imagePreview //destructuring
          const handleTextAreaChange = (ev: any): void => {
                imagePreview.description = ev.target.value;
          }
          return (
             <div key={url} className="previewContainer">
                <span className="close" onClick={() => removeImage(url)}></span>
                <img className="previewImage" src={url} />
                <textarea 
                placeholder="Fill in directions here, e.g. Gate code is 5432. 
                Turn left at the first elevator..."
                className="instructions" 
                onChange={event => { handleTextAreaChange(event) }}/>
             </div>
          )
       })
      }

    // specify upload params and url for your files
    const getUploadParams = async (name: string) => {
        const s3SigningMethod = "post";
        const { fields, url } = await getS3SignedUrl(UNIQUE_ID + "/" + name, s3SigningMethod);
        return { fields, url }
    }

    const addMultipleFiles = async (e: any) => {
        for (let i = 0; i < e.target.files.length; i++) {
            const originalImage = e.target.files[i];
            //re-name image to ensure uniqueness
            const newFileName = UNIQUE_ID + "_" + Math.floor(Date.now() /1000) + "_" + i;
            const newImage = new File([originalImage], newFileName, {
                type: originalImage.type,
                lastModified: originalImage.lastModified,
            });

            const imageUrl = URL.createObjectURL(newImage);
            addImage(imageUrl, newImage);
        }
        ReactGA.event({
            category: "User",
            action: "Uploaded image",
            value: e.target.files.length,
            label: UNIQUE_ID
        });
        e.target.value = null;
    }

    const uploadFiles = async () => {
        setfileDetails("id", UNIQUE_ID);
        let t0 = performance.now();
        await Promise.all(filePreviews.map(async (imagePreview) => {
            const compressedImage = await imageCompression(imagePreview.file, compressionOptions)
            .catch(error => {
                console.error("An error occured compressing the image.");
                ReactGA.exception({
                    description: 'An error occured compressing the image.',
                    label: UNIQUE_ID,
                    fatal: true
                  });
            });
            const uploadParams = await getUploadParams(imagePreview.name)
            .catch(error => {
                console.error("An error occured getting the pre-signed URL.");
                ReactGA.exception({
                    description: 'An error occured getting the pre-signed URL.',
                    label: UNIQUE_ID,
                    fatal: true
                  });
            });
            const uploadFileResults = await uploadFileToS3(compressedImage, uploadParams)
            .catch(error => {
                console.error("An error occured uploading file to S3.");
                ReactGA.exception({
                    description: 'An error occurred uploading file to S3.',
                    label: UNIQUE_ID,
                    fatal: true
                  });
            });
        })).then(() => {
            let t1 = performance.now();
            ReactGA.timing({
              category: "API Latency",
              variable: "uploadFiles",
              value: t1 - t0,
              label: UNIQUE_ID
            });
            return Promise.resolve();
        })
    }

    const submit = async (e: any) => {
        e.preventDefault();

        // show loading graphic
        setShowLoadingSpinner(true);
        await uploadFiles().then(() => {
            let t0 = performance.now();
            persistDirections(UNIQUE_ID, filePreviews).then(data => {
                let t1 = performance.now();
                ReactGA.timing({
                  category: 'API Latency',
                  variable: 'persistsDirections',
                  value: t1 - t0,
                  label: UNIQUE_ID
                });
                setShowLoadingSpinner(false);
                history.push("/confirmation");
            }, err => {
                console.error("Failed to save directions: ", err);
                ReactGA.exception({
                    description: 'Error saving directions to database.',
                    label: UNIQUE_ID,
                    fatal: true
                  });
            });
        });
    }

    return (
        <div className="container">
            {showLoadingSpinner ? 
                <div className="background-loading">
                <div className="loading">
                <div className="spinner"></div>
                <p> Building your unique link... </p>
                </div>
            </div> : ""}
        <div className="row">
          <div className="one-third column filler">&nbsp;</div>
          <div className="one-third column">
                {renderFilePreview()}
                <label className={filePreviews.length > 0 ? "inputLabelWithFiles" : "inputLabel"} >
                Tap to upload photos
                <input type="file" className="fileUpload" onChange={addMultipleFiles} multiple/>
            </label>
            <button className="submitButton" type="button" onClick={submit}>Submit</button>
        </div>
         <div className="one-third column filler">&nbsp;</div>
        </div>
        </div>
    )
}

export default MultipleImageUploadComponent;
