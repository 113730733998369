import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { storeUserEmail } from "./homepage.api";
import { showToast } from "../utils/toast.utils";
import ReactGA from 'react-ga';

require("./homepage.css");
require("../css/microdirections.css");
require("../css/skeleton/normalize.css");
require("../css/skeleton/skeleton.css");

function HomePage() {
  let history = useHistory();
  const [input, setInput] = useState({ email: "", zipcode: "", selectedRole: "" });
  const rotatingList = ['delivery drivers.', 'guests and visitors.', 'property managers.', 'first-responders.', 'apartment residents.'];
  
  setTimeout(ReactGA.pageview("/homepage"), 1000);
  
  // textSequence(0);
  // function textSequence(i) {
  //     if (rotatingList.length > i) {
  //         setTimeout(function() {
  //             //document.getElementById("sequence").style.visibility = 'hidden';
  //             document.getElementById("sequence").innerHTML = rotatingList[i];
  //             //document.getElementById("sequence").style.visibility = 'visible';
  //             textSequence(++i);
  //         }, 4000); // 1 second (in milliseconds)

  //     } else if (rotatingList.length == i) { // Loop
  //         textSequence(0);
  //     }
  // }

  const submitForm = (event) => {
    event.preventDefault();
    storeUserEmail(input);
    showToast("Awesome! See you soon.");
    setInput(() => ({
      email: "",
      zipcode: "",
      selectedRole: "",
    }));
  };

  const handleInputChange = (event) => {
    event.persist();
    setInput((input) => ({
      ...input,
      [event.target.name]: event.target.value,
    }));
  };
  
  const visitApp = () => {
    ReactGA.event({
      category: 'User',
      action: 'Visit app'
    });
    history.push("/app");
  }

  return (

    <div id="outer"> 
    <div id="col1"></div>
    <div id="col2"></div>
    <div id="col3"></div>
    <div id="col4"></div>
    <div id="col5"></div>
    <div id="col6"></div>
    <div id="clearer"></div>
    <div className="container">
      <span className="heading">Deliverease.</span>
      <section className="section-one">
        <div className="top-section">
          <div className="desktop-landing-text">
            <span> Deliverease eliminates friction for deliveries</span> <br />
            by providing interior microdirections and access control to apartment residents.
            <br /><br />
            <span class="visit-app" onClick={visitApp}>Visit our app</span> to save time on deliveries now.
          </div>
        </div>
      </section>
      <section className="section-two">
        <div className="call-to-action">
          Find out when we launch in your area:
        </div>
        <form className="landing-form">
          <div className="row">
            <div className="field">
              <input
                className="email-input"
                type="email"
                name="email"
                placeholder="email@mailbox.com"
                id="exampleEmailInput"
                value={input.email}
                onChange={handleInputChange}
              />
            </div>
            <div className="field">
              <input
                className="zip-input"
                type="text"
                name="zipcode"
                placeholder="zipcode (e.g. 94040)"
                pattern="[0-9]{5}"
                id="exampleEmailInput"
                value={input.zipcode}
                onChange={handleInputChange}
              />
            </div>
            <div className="field">
            <select name="selectedRole" value={input.selectedRole} onChange={handleInputChange} required>
              <option value="" defaultValue hidden>I am a ... (choose one)</option>
              <option value="resident">Resident</option>
              <option value="deliveryCourier">Delivery Courier</option>
              <option value="propertyManager">Property Owner / Manager</option>
              <option value="other">Other</option>
            </select>
            </div>
            <div className="field">
              <button
                className="landing-button button-primary"
                type="submit"
                onClick={submitForm}
              >
                {"Get Notified"}
              </button>
            </div>
          </div>
        </form>
      </section>
    </div>
  </div>
  );
}

export default HomePage;
