import { get, post, postS3 } from "../ApiProvider";

const preSignedUrlUri = "/s3/signing";
const directionsUri = "/directions";

const getS3SignedUrl = async (fileName, s3SigningMethod) => {
  const params = {
    "params": {
      "fileName": fileName,
      "method": s3SigningMethod,
    },
  };
  return await get(preSignedUrlUri, params);
};

const uploadFileToS3 = async (imageFile, presignedUrlObjct) => {
  const formData = new FormData();
  Object.keys(presignedUrlObjct.fields).forEach(key => {
    formData.append(key, presignedUrlObjct.fields[key]);
  });
  // Actual file has to be appended last.
  formData.append("file", imageFile);

  const config = {
    headers: { "Content-Type": imageFile["type"] },
  };
  return await postS3(presignedUrlObjct["url"], formData, config);
}

const persistDirections = async (id, files) => {
  const payload = {
    "id": id,
    "directions": []
  }
  for (let i = 0; i < files.length; i++) {
    console.log(files[i])
    payload.directions.push({
      "orderKey": i + 1,
      "imageKey": files[i].name,
      "instructions": files[i].description,
    })
  }
  console.log(payload);
  return await post(directionsUri, payload);
}

export { getS3SignedUrl, persistDirections, uploadFileToS3 };
