import { get } from "../ApiProvider";

const preSignedUrlEndpoint = "/s3/signing";
const directionsUri = "/directions";

const getS3SignedUrl = async (fileName, s3SigningMethod) => {
  const params = {
    "params": {
      "fileName": fileName,
      "method": s3SigningMethod,
    },
  };
  return await get(preSignedUrlEndpoint, params);
};

const getDirections = async (id) => {
  const params = {
    "params": {
      "uniqueId": id,
    },
  };
  return await get(directionsUri, params);
}

export { getS3SignedUrl, getDirections };
