import React from "react";
import { useGlobalState } from "../state";
import { showToast } from "../utils/toast.utils";
import ReactGA from 'react-ga';

import "./confirmation.css";
import "../css/skeleton/normalize.css";
import "../css/skeleton/skeleton.css";


function ShowConfirmation() {
  let [{ id }] = useGlobalState("fileDetails");
  const uniqueUrl = "https://app.getdeliverease.com/directions/" + id;

  setTimeout(() => ReactGA.pageview("/confirmation"), 1000);

  const tapCopyId = () => {
    ReactGA.event({
      category: "User",
      action: "Copied link",
      label: id
    });
    const textarea = document.createElement("textarea");
    textarea.textContent = uniqueUrl;
    textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in Microsoft Edge.
    document.body.appendChild(textarea);
    textarea.select();
    try {
        return document.execCommand("copy");  // Security exception may be thrown by some browsers.
    }
    catch (ex) {
        console.warn("Copy to clipboard failed.", ex);
        ReactGA.exception({
          description: 'Copying link to clipboard failed.',
          label: id,
          fatal: false
        });
        return false;
    }
    finally {
        document.body.removeChild(textarea);
        showToast("Copied link!")
    }
  }

  return (
    <div>
    <div id="outer">
    <div id="col1"></div>
    <div id="col2"></div>
    <div id="col3"></div>
    <div id="col4"></div>
    <div id="col5"></div>
    <div id="col6"></div>
    </div>
    <div id="clearer"></div>
    <div className="container">
      <div className="row">
        <div className="one-third column filler">&nbsp;</div>
        <div className="one-third column">
        <div className="heading-center">Deliverease.</div>
    <div className="confirmation-text">
     <b>Tap on the unique link below to copy. </b>
     Paste it into the delivery notes section of your favorite delivery apps! <br />
    <div className="unique-link" onClick={tapCopyId}>
      app.getdeliverease.com<wbr />/directions/{id}
    </div>
    </div>
          </div>
          <div className="one-third column filler">&nbsp;</div>
      </div>
    </div>
    </div>
  );
}

export default ShowConfirmation;
