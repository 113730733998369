import React, { useState, useEffect} from "react";
import { showToast } from "../utils/toast.utils";
import { getS3SignedUrl, getDirections } from "./directions.api"
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import ReactGA from 'react-ga';

require("./directions.css");
require("../css/skeleton/normalize.css");
require("../css/skeleton/skeleton.css");

const getDirectionsById = async (id) => {
  const directionsObject = await getDirections(id);
  const stepList = directionsObject["body"]["steps"];
  stepList.sort((a, b) => {return a["order"] - b["order"]});
  return stepList;
}

function DisplayDirections() {
  let history = useHistory();
  let { directionsId } = useParams();
  const [steps, setSteps] = useState(null);

  setTimeout(() => ReactGA.pageview("/directions/" + directionsId), 1000);

  useEffect(() => {
      let t0 = performance.now();
      async function getSteps(directionsId) {
          const stepList = await getDirectionsById(directionsId);
          setSteps(stepList);
          let t1 = performance.now();
          ReactGA.timing({
            category: "API Latency",
            variable: "displayDirections",
            label: directionsId,
            value: t1 - t0
          });
      }
      getSteps(directionsId);
  }, [])

  const renderStepHtml = () => {
    if (steps == null) return (<div></div>)
    return steps.map((s, index) => 
    <div key={s.order}>
      <div className="display-step">
        <h3> Step {index + 1}</h3>
        <img src={s.imageUrl} />
        <p className="instruction-text"> {s.instructions} </p>
      </div>
      </div>
    )
  }

  const clickReferalLink = () => {
    ReactGA.event({
      category: 'User',
      action: 'Clicked referal link'
    });
    history.push("/app");
  }

  return (
    <div>
      <div id="outer">
      <div id="col1"></div>
      <div id="col2"></div>
      <div id="col3"></div>
      <div id="col4"></div>
      <div id="col5"></div>
      <div id="col6"></div>
      </div>
      <div id="clearer"></div>
      <div className="container">
      <div className="row">
        <div className="one-third column filler">&nbsp;</div>
        <div className="one-third column">
          <div className="heading-center">Deliverease.</div>
          <div>
            {renderStepHtml()}
          </div>
          <div className="footer-text">
            Want to make sure your deliveries always get delivered?
            <a href="" onClick={clickReferalLink}> See how with Deliverease.</a>
          </div>
        </div>
          <div className="one-third column filler">&nbsp;</div>
      </div>

    </div>
    </div>
  );
}

export default DisplayDirections;
